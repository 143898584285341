<template>
  <responsive-menu
    :activator-class="activatorClass"
    :activator-title="$t('t.Filters')"
    v-model="computedMenuIsOpen"
  >
    <suggestion-list-filter
      data-cy="search-filter-list"
      ref="list"
      :loading="categorySearch.loading || itemSearch.loading || filterParamLoading"
      display-search
      :search-text.sync="itemSearch.searchText"
      @update:search-text="updateDocumentTypes"
      :categories="categorySearch.items"
      :items="itemSearch.items"
      :page-size="pageSize"
      :show-detail="false"
      :selected-category.sync="dataCategory"
      :hide-categories="computedHideCategories"
      :icon-props="{small: false}"
      @update:selected-category="changeCategory"
      @click:item="$refs[`checkbox-${$event.item.type}-${$event.item.id}`].toggle($event.$event)"
      @categories-end-reached="categorySearch.retrieveNextPage()"
      @items-end-reached="nextItemsPage()"
    >
      <template v-slot:append-search>
        <toggle-consolidated
          class="ml-2"
          :consolidable="consolidable"
          :consolidated.sync="computedConsolidated"
          :readonly="readonly"
        />
        <v-btn-toggle
          data-cy="toggle-button"
          class="ml-2"
          v-model="selectedOnlyMode"
          borderless
          v-if="!isEmpty"
        >
          <v-btn
            icon
            small
            :value="true"
          >
            <icon-tooltip
              :icon-name="'i.CheckAll'"
              :tooltip-text="selectedOnlyMode ? $t('t.ShowAllItems') : $t('t.ShowActiveFilters')"
            />
          </v-btn>
        </v-btn-toggle>
      </template>
      <template v-slot:prepend-action="{ item }">
        <v-list-item-action>
          <inc-exc-switch
            :ref="`checkbox-${item.type}-${item.id}`"
            :key="`checkbox-${item.type}-${item.id}`"
            icon
            small
            :disabled="filterParamLoading"
            :value="incExcSwitchValue(item)"
            @update:value="setSelectedValues(item, $event)"
          />
        </v-list-item-action>
      </template>
      <template v-slot:append-action="{ item }">
        <v-list-item-action v-if="showPin && isPinable(item)">
          <slot
            name="pins"
            :item="item"
          />
        </v-list-item-action>
      </template>
    </suggestion-list-filter>
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-badge
          v-if="showPin"
          overlap
          :value="selectedCount"
          bordered
        >
          <v-chip
            :color="computedColor"
            @mouseover="$emit('mouseover')"
            @mouseleave="$emit('mouseleave')"
            v-on="on"
          >
            <icon-tooltip
              icon-name="i.Filters"
              :tooltip-text="$t('t.Filters')"
            />
          </v-chip>
          <template v-slot:badge>{{selectedCount}}</template>
        </v-badge>
        <v-chip
          v-else
          :close="!isEmpty && !readonly"
          :color="computedColor"
          @click:close="clearSelection"
          :close-icon="$icon('i.ClearList')"
          @mouseover="$emit('mouseover')"
          @mouseleave="$emit('mouseleave')"
          dense
        >
          <div
            class="d-flex align-center"
            v-if="!isEmpty && displayValue"
          >
            <inc-exc-switch
              :value="displayValue.inc"
              small
              disabled
              color="white"
              class="mr-2"
            />
            <span v-if="displayValue.id === null">
              <v-icon small>{{$icon(`i.${toPascalCase(displayValue.type)}`)}}</v-icon>
              {{$t('t.None')}}
            </span>
            <document-picker-list-item-ref
              class="filter-label"
              v-else-if="displayValue.id"
              :category-props="{ visible: false }"
              :user-ref-props="{ hideEmail: true, hidePhone:true, hideGroup: true}"
              :item="displayValue"
              show-icon
            />
            <span
              class="filter-label ml-1"
              v-if="selectedCount > 1"
            >+{{selectedCount - 1}}</span>
          </div>
          <div
            class="d-flex flex-row align-center"
            v-else-if="isEmpty"
          >
            <span class="filter-label">{{computedLabel}}</span>
          </div>
          <slot
            v-if="category"
            name="pins-category"
            :item="category"
            :isSelected="selectedCount > 0"
          />
          <toggle-consolidated
            :consolidated.sync="computedConsolidated"
            readonly
          />
        </v-chip>
      </div>
    </template>
  </responsive-menu>
</template>

<script>
import FilterParam from '@/pages/search/controllers/filter-param'
import Search from '@/pages/search/controllers'

export default {
  components: {
    DocumentPickerListItemRef: () => import('@/components/document-picker-list-item-ref'),
    IncExcSwitch: () => import('./inc-exc-switch'),
    SuggestionListFilter: () => import('./suggestion-list-filter'),
    ResponsiveMenu: () => import('@/pages/search/components/responsive-menu'),
    ToggleConsolidated: () => import('@/components/toggle-consolidated'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  beforeDestroy () {
    this.categorySearch = undefined
    this.itemSearch = undefined
  },
  computed: {
    computedConsolidated: {
      get () {
        return this.filter?.consolidated
      },
      set (v) {
        if (this.consolidable) {
          this.$emit('setConsolidated', v)
        }
      }
    },
    computedColor () {
      return this.isEmpty ? (this.$vuetify.theme.isDark ? undefined : 'white') : 'primary'
    },
    computedHideCategories () {
      return this.selectedOnlyMode || !!this.category?.id
    },
    isEmpty () {
      return !this.computedSelectedIds.length
    },
    computedMenuIsOpen: {
      get () {
        return this.dataMenuIsOpen
      },
      async set (isOpen) {
        this.dataMenuIsOpen = isOpen
        if (isOpen) {
          // US 12856: If there is no selected filter, always open the menu on cateogry selection
          if (this.isEmpty && !this.category) {
            this.$refs?.list?.unsetCategory()
            // If there is a list to display, scroll to top
            if (this.$refs?.list?.$refs?.categories?.$el) {
              await this.$waitFor(() => this.$refs?.list?.$refs?.categories?.$el?.checkVisibility())
              this.$refs.list.$refs.categories.resetScroll()
            }
          }

          this.selectedOnlyMode = this.startWithSelectedOnlyMode && !!this.selectedCount
          if (!this.categorySearch.items.length && !this.computedHideCategories) {
            await this.categorySearch.execute()
          }
          if (
            (!this.itemSearch.items.length || this.dataCtxChange) &&
            (this.computedHideCategories || this.categorySearch.items.length <= 1)
          ) {
            this.itemSearch.clearItems()
              .setPage(1)
              .clearText().execute()
          }
          if (this.selectedOnlyMode) {
            this.itemSearch.chain(s => s.searchedIds.clear().include(this.computedSelectedIds))
              .clearItems()
              .setPage(1)
              .clearText()
              .chain(s => s.searchedDocumentTypes.clear())
              .execute()
          }
        }
        this.dataCtxChange = false
      }
    },
    computedSelectedIds () {
      return this.filter?.selectedIds(this.category) ?? []
    },
    displayValue () {
      return !this.showPin ? this.filter?.displayValue(this.category) : null
    },
    computedLabel () {
      return this.label ?? (this.dataCategory?.id ? this.$t(`t.${this.toPascalCase(this.dataCategory.id)}`) : '')
    },
    selectedCount () {
      return this.computedSelectedIds.length
    }
  },
  data () {
    const pageSize = 10
    const itemSearch = new Search(this.targetSearch ? Search.actions.PIN : Search.actions.SUGGEST)
      .setPageSize(pageSize)
      .chain(s => s.searchedDocumentTypes.exclude(['auto-filters']))
      .setAutoFilterType(this.autoFilterType)
    if (this.targetSearch) {
      itemSearch
        .setMainSearch(this.targetSearch, true)
        .setCategory(this.category.id)
    }
    return {
      dataCategory: null,
      dataCtxChange: false,
      pageSize,
      selectedOnlyMode: false,
      itemSearch,
      categorySearch: new Search()
        .setPageSize(pageSize)
        .chain(s => s.searchedDocumentTypes.include(['auto-filters']))
        .setAutoFilterType(this.autoFilterType),
      dataMenuIsOpen: false,
      filterParamLoading: false,
      lockNextItemsPage: false
    }
  },
  methods: {
    changeCategory (category) {
      if (category && !this.itemSearch.searchedDocumentTypes.isIncluded(category.id)) {
        this.itemSearch
          .clearItems()
          .setPage(1)
          .chain(s => s.searchedDocumentTypes.clear().include([category.id]))
          .execute()
      }
    },
    clearSelection () {
      this.$emit('clearSelection')
    },
    incExcSwitchValue (item) {
      return this.filter.incExcSwitchValue(item)
    },
    isPinable (item) {
      return item?.id !== 'columns'
    },
    nextItemsPage () {
      if (this.lockNextItemsPage) { return }
      this.itemSearch.retrieveNextPage()
    },
    async setSelectedValues (item, incExcSwitchValue) {
      if (!this.filterParamLoading) {
        this.filterParamLoading = true
        await this.$parent.setSelectedValues(item, incExcSwitchValue)
        if (!incExcSwitchValue.key.shiftKey && !incExcSwitchValue.key.ctrlKey && !isNaN(incExcSwitchValue.value)) {
          this.computedMenuIsOpen = false
        }
        this.filterParamLoading = false
      }
    },
    setAutoFilterType () {
      this.$refs?.list?.unsetCategory()
      this.categorySearch.chain(s => s.searchedIds.clear())
        .setPage(1)
        .clearText()
        .setAutoFilterType(this.autoFilterType)
        .clearItems()
      this.itemSearch.chain(s => s.searchedIds.clear())
        .setPage(1)
        .clearText()
        .chain(s => s.searchedDocumentTypes.clear().exclude(['auto-filters']))
        .setAutoFilterType(this.autoFilterType)
        .clearItems()
      this.dataCtxChange = true
      this.selectedOnlyMode = this.startWithSelectedOnlyMode && !!this.selectedCount
    },
    updateDocumentTypes () {
      this.selectedOnlyMode = false
      if (!this.dataCategory) {
        this.itemSearch.searchedDocumentTypes.clear().exclude(['auto-filters'])
      }
    }
  },
  watch: {
    category: {
      immediate: true,
      handler (v) {
        if (v) {
          this.dataCategory = this.lodash.cloneDeep(v)
          this.itemSearch.chain(s => s.searchedDocumentTypes.clear().include([v.id]))
          this.dataCtxChange = true
        }
      }
    },
    isEmpty (v) {
      if (v) {
        this.selectedOnlyMode = false
        this.dataCtxChange = true
      }
    },
    selectedOnlyMode (filtered) {
      let ids = []

      if (filtered) {
        this.$refs?.list?.unsetCategory()
        ids = this.computedSelectedIds
      } else if (!this.categorySearch.items.length && !this.category?.id) {
        this.categorySearch.execute()
      }

      this.itemSearch.chain(s => s.searchedIds.clear().include(ids))
        .setPage(1)
        .chain(s => s.searchedDocumentTypes.clear())

      if (this.category?.id) {
        this.itemSearch.chain(s => s.searchedDocumentTypes.include([this.category.id]))
        if (!filtered) {
          this.itemSearch.execute()
        }
      }

      if (this.computedMenuIsOpen && filtered) {
        this.lockNextItemsPage = true
        this.itemSearch.execute().then(() => { this.lockNextItemsPage = false })
      } else {
        this.dataCtxChange = false
      }
    },
    // TODO Remove setTimeout / Clean-up
    autoFilterType (v) {
      this.computedMenuIsOpen = false
      setTimeout(this.setAutoFilterType, 500)
    }
  },
  props: {
    activatorClass: String,
    autoFilterType: String,
    consolidable: Boolean,
    category: Object,
    filter: FilterParam,
    label: String,
    readonly: Boolean,
    showPin: Boolean,
    targetSearch: Search,
    startWithSelectedOnlyMode: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.filter-label
  margin-top 1px
</style>
